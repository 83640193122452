export interface IAppUser {
  profile: string;
  lastName: string;
  names: string;
  uid: string;
  email: string;
  lastLogInAt?: string;
  createdAt: string;
  updatedAt: string;
}

export class AppUser {
  profile: string;
  lastName: string;
  names: string;
  uid: string;
  email: string;
  lastLogInAt?: string;
  createdAt: string;
  updatedAt: string;

  constructor(data: IAppUser) {
    this.profile = data.profile;
    this.lastName = data.lastName;
    this.names = data.names;
    this.uid = data.uid;
    this.email = data.email;
    this.lastLogInAt = data.lastLogInAt;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  getFullName(): string {
    return `${this.names} ${this.lastName}`;
  }

  get fullName(): string {
    return `${this.names} ${this.lastName}`;
  }

  // Aquí puedes agregar otros métodos útiles relacionados con la clase AppUser
}
