import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'toLocalDate'
})
export class ToLocalDatePipe implements PipeTransform {

  transform(value: string | Date | undefined): string {
    if (!value) return '';

    const localDate = moment(value).add(-6, 'hours').format('DD-MM-YYYY HH:mm:ss');
    return localDate;
  }

}
