export interface IFileApp {
  urlFile: string;
  path: string;
  fileType: string;
  uidUpdatedBy?: string;
  uidCreatedBy?: string;
  updatedAt: string;
  createdAt: string;
  idFile: number;
  service: string;
}

export class FileApp {
  urlFile: string;
  path: string;
  fileType: string;
  uidUpdatedBy?: string;
  uidCreatedBy?: string;
  updatedAt: Date;
  createdAt: Date;
  idFile: number;
  service: string;

  constructor(data: IFileApp) {
    this.urlFile = data.urlFile;
    this.path = data.path;
    this.fileType = data.fileType;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.uidCreatedBy = data.uidCreatedBy;
    // Convertir las cadenas de fecha a objetos Date para un manejo más conveniente
    this.updatedAt = new Date(data.updatedAt);
    this.createdAt = new Date(data.createdAt);
    this.idFile = data.idFile;
    this.service = data.service;
  }
}
