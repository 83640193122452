export const environment = {
  production: false,
  firebase: {
    projectId: "varever-estoico",
    appId: "1:44587575979:web:0dd0b301d1ad9f781825ee",
    storageBucket: "varever-estoico.appspot.com",
    apiKey: "AIzaSyCuTHGefRyuDS4Ung_UsSgjYqGl8x_2Btw",
    authDomain: "varever-estoico.firebaseapp.com",
    messagingSenderId: "44587575979",
    measurementId: "G-3FQ17Y1GKR"
  },
  urlApi: "https://qdd0mlu401.execute-api.us-east-1.amazonaws.com/dev/api/v1"
};
