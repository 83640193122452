import { AppUser, IAppUser } from "./app-user.model";
import { FileApp, IFileApp } from "./file-app.model";

export interface ICompany {
  idCompany: string;
  uidOwner: string;
  idFile?: string;
  name: string;
  zipCode: string;
  neighborhood: string;
  city: string;
  state: string;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
  owner?: IAppUser;
  createdBy?: IAppUser;
  updatedBy?: IAppUser;
  file?: IFileApp;
}

export class Company {
  idCompany: string;
  uidOwner: string;
  idFile?: string;
  name: string;
  zipCode: string;
  neighborhood: string;
  city: string;
  state: string;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
  owner?: AppUser;
  createdBy?: AppUser;
  updatedBy?: AppUser;
  file?: FileApp;

  constructor(data: ICompany) {
    this.idCompany = data.idCompany;
    this.uidOwner = data.uidOwner;
    this.idFile = data.idFile;
    this.name = data.name;
    this.zipCode = data.zipCode;
    this.neighborhood = data.neighborhood;
    this.city = data.city;
    this.state = data.state;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.owner = data.owner
      ? new AppUser(data.owner)
      : undefined;
    this.createdBy = data.createdBy
      ? new AppUser(data.createdBy)
      : undefined;
    this.updatedBy = data.updatedBy
      ? new AppUser(data.updatedBy)
      : undefined;
    this.file = data.file
      ? new FileApp(data.file)
      : undefined;
  }

  getFullAddress(): string {
    return `${this.neighborhood}, ${this.city}, ${this.state}, ${this.zipCode}`;
  }

}