import { Injectable } from '@angular/core';
import { Company, ICompany } from '../models/company.model';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';
import { AuthService } from './auth.service';
import { SpinnerService } from './spinner.service';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public companies: Array<Company> = [];
  public currentCompany?: Company;
  public loading = false;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
  ) { 
    this.getAll();
  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
 /**
  * FUNCTION create
  * 
  * @param company 
  * @returns 
  */
  async create(
    company: ICompany
  ): Promise<boolean> {
    let flag = false;

    company.neighborhood = company.neighborhood.toUpperCase();
    company.city = company.city.toUpperCase();
    company.state = company.state.toUpperCase();

    const urlApi = `${environment.urlApi}/companies/`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando compañias';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(company),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      // console.log(response);

      if(response.statusCode === 201) {
        flag = true;
        this.getAll();
      }
      

    } catch(error) {
      console.log(error);
      
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION delete
   * 
   * @param company 
   * @returns 
   */
  async delete(
    company: Company
  ): Promise<boolean> {
    let flag = false;

    const urlApi = `${environment.urlApi}/companies/${company.idCompany}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Borrando compañia';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;
        this.getAll();
      }
      

    } catch(error) {
      console.log(error);
      
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
   ██████╗ ███████╗████████╗
  ██╔════╝ ██╔════╝╚══██╔══╝
  ██║  ███╗█████╗     ██║
  ██║   ██║██╔══╝     ██║
  ╚██████╔╝███████╗   ██║
   ╚═════╝ ╚══════╝   ╚═╝

  */
  /**
   * FUNCTION get
   */
  public async get(idCompany: string) {

    const urlApi = `${environment.urlApi}/companies/${idCompany}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Obteniendo compañia';

    try {

      // const user = await this.authService.currentUser
      const token = await this.authService.getToken();

      console.log(
        "token: ", token
      );

      const response = await new Promise<ServerResponse<{ company: ICompany }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ company: ICompany }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ company: ICompany }>
              ) => resolve(
                new ServerResponse<{ company: ICompany }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);
      

      if (response.statusCode === 200) {
        this.currentCompany = new Company(response.data?.company!)
      }

    } catch(error) {
      console.log(error);
      
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
 /**
  * FUNCTION getAll
  */
  public async getAll() {

    const urlApi = `${environment.urlApi}/companies/`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Obteniendo compañias';

    try {

      // const user = await this.authService.currentUser
      const token = await this.authService.getToken();

      console.log(
        "token: ", token
      );

      const response = await new Promise<ServerResponse<{ companies: ICompany[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ companies: ICompany[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ companies: ICompany[] }>
              ) => resolve(
                new ServerResponse<{ companies: ICompany[] }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);
      

      if (response.statusCode === 200) {
        if (response.data?.companies) {
          this.companies = response.data?.companies.map(
            (company) => new Company(company)
          );
        }
      }

    } catch(error) {
      console.log(error);
      
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION update
   * 
   * @param company 
   * @returns
   */
  async update(
    company: Company,
    newCompany: ICompany
  ): Promise<boolean> {
    let flag = false;

    newCompany.neighborhood = company.neighborhood.toUpperCase();
    newCompany.city = company.city.toUpperCase();
    newCompany.state = company.state.toUpperCase();

    const urlApi = `${environment.urlApi}/companies/${company.idCompany}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando compañia';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(newCompany),
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;
        this.getAll();
      }
      

    } catch(error) {
      console.log(error);
      
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }
}
