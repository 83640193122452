import { Injectable } from '@angular/core';
import { Auth, signOut, user, User } from '@angular/fire/auth';
import { Router } from '@angular/router';

import { LoaderService } from './loader.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';
import { AppUser, IAppUser } from '../models/app-user.model';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn: boolean = false;

  public currentFirebaseUser?: User;

  public currentUser?: AppUser;
  public subCurrentUser: Subject<AppUser> = new Subject<AppUser>();

  constructor(
    public auth: Auth,
    private http: HttpClient,
    private loaderService: LoaderService,
    private router: Router
  ) {
    user(this.auth).subscribe((_user) => {
      if (_user) {
        console.log('Existe usuario');
        
        this.loggedIn = true;
        this.currentFirebaseUser = _user;
        this.getUser();
      } else {
        console.log('NO Existe usuario');
        this.loggedIn = false;
      }
    });
  }

  /*
   ██████╗ ███████╗████████╗███████╗██╗██████╗ ███████╗██████╗  █████╗ ███████╗███████╗██╗   ██╗███████╗███████╗██████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔════╝██║██╔══██╗██╔════╝██╔══██╗██╔══██╗██╔════╝██╔════╝██║   ██║██╔════╝██╔════╝██╔══██╗
  ██║  ███╗█████╗     ██║   █████╗  ██║██████╔╝█████╗  ██████╔╝███████║███████╗█████╗  ██║   ██║███████╗█████╗  ██████╔╝
  ██║   ██║██╔══╝     ██║   ██╔══╝  ██║██╔══██╗██╔══╝  ██╔══██╗██╔══██║╚════██║██╔══╝  ██║   ██║╚════██║██╔══╝  ██╔══██╗
  ╚██████╔╝███████╗   ██║   ██║     ██║██║  ██║███████╗██████╔╝██║  ██║███████║███████╗╚██████╔╝███████║███████╗██║  ██║
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝     ╚═╝╚═╝  ╚═╝╚══════╝╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

  */
 /**
  * FUNCTION getFirebaseUser
  * 
  * @returns 
  */
  async getFirebaseUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      
      if(this.currentFirebaseUser) {
        resolve(this.currentFirebaseUser);
      } else {
        user(this.auth).subscribe((_user) => {
          if (_user) {
            console.log('Existe usuario');
            
            this.loggedIn = true;
            this.currentFirebaseUser = _user;
            this.getUser();
            resolve(_user);
          } else {
            console.log('NO Existe usuario');
            this.loggedIn = false;
            resolve(null);
          }
        });
      }

    });
    
  }

  /*
   ██████╗ ███████╗████████╗████████╗ ██████╗ ██╗  ██╗███████╗███╗   ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔═══██╗██║ ██╔╝██╔════╝████╗  ██║
  ██║  ███╗█████╗     ██║      ██║   ██║   ██║█████╔╝ █████╗  ██╔██╗ ██║
  ██║   ██║██╔══╝     ██║      ██║   ██║   ██║██╔═██╗ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝███████╗   ██║      ██║   ╚██████╔╝██║  ██╗███████╗██║ ╚████║
   ╚═════╝ ╚══════╝   ╚═╝      ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝  ╚═══╝

  */
 /**
  * FUNCTION getToken
  * 
  * @returns 
  */
  async getToken(): Promise<String> {
    const user = await this.getFirebaseUser();

    const token = await user?.getIdToken();
    
    return token ?? '';
  }

  /*
   ██████╗ ███████╗████████╗██╗   ██╗███████╗███████╗██████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██║   ██║██╔════╝██╔════╝██╔══██╗
  ██║  ███╗█████╗     ██║   ██║   ██║███████╗█████╗  ██████╔╝
  ██║   ██║██╔══╝     ██║   ██║   ██║╚════██║██╔══╝  ██╔══██╗
  ╚██████╔╝███████╗   ██║   ╚██████╔╝███████║███████╗██║  ██║
   ╚═════╝ ╚══════╝   ╚═╝    ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

  */
 /**
  * FUNCTION getUser
  */
  async getUser() {
    this.loaderService.loading = true;
    this.loaderService.message = 'Cargando datos de usuario';

    const token = await this.currentFirebaseUser?.getIdToken();
    // console.log('token: ', token);
    
    const urlApi = `${environment.urlApi}/account/`;

    try {

      const response = await new Promise<ServerResponse<{ user: IAppUser }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ user: IAppUser }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token ?? ''
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{ user: IAppUser }>
              ) => resolve(
                new ServerResponse<{ user: IAppUser }>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        this.currentUser = new AppUser(
          response.data?.user!
        );

        this.subCurrentUser.next(this.currentUser);
      }

      console.log(
        this.currentUser,
      );
      

    } catch(error) {
      console.log(error);
    }


    this.loaderService.loading = false;
    this.loaderService.message = '';
  }

  /*
  ███████╗██╗ ██████╗ ███╗   ██╗ ██████╗ ██╗   ██╗████████╗
  ██╔════╝██║██╔════╝ ████╗  ██║██╔═══██╗██║   ██║╚══██╔══╝
  ███████╗██║██║  ███╗██╔██╗ ██║██║   ██║██║   ██║   ██║
  ╚════██║██║██║   ██║██║╚██╗██║██║   ██║██║   ██║   ██║
  ███████║██║╚██████╔╝██║ ╚████║╚██████╔╝╚██████╔╝   ██║
  ╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝  ╚═════╝    ╚═╝

  */
 /**
  * FUNCTION signOut
  */
  async signOut() {
    this.loaderService.loading = true;
    this.loaderService.message = 'Cerrando sesión';

    await signOut(this.auth);

    this.currentUser = undefined;

    this.loaderService.loading = false;
    this.loaderService.message = '';

    sessionStorage.removeItem('user');
    this.loggedIn = false;
    this.router.navigate(['login']);
  }

  /*
  ███████╗██╗ ██████╗ ███╗   ██╗██╗   ██╗██████╗
  ██╔════╝██║██╔════╝ ████╗  ██║██║   ██║██╔══██╗
  ███████╗██║██║  ███╗██╔██╗ ██║██║   ██║██████╔╝
  ╚════██║██║██║   ██║██║╚██╗██║██║   ██║██╔═══╝
  ███████║██║╚██████╔╝██║ ╚████║╚██████╔╝██║
  ╚══════╝╚═╝ ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝ ╚═╝

  */
  /**
   * FUNCTION signUp
   * 
   * @param data 
   */
  async signUp(data: {
    email: string,
    password: string,
    names: string,
    lastName: string,
    profile: string,
    code?: string
  }): Promise<ServerResponse | undefined> {

    let responseServer = undefined;

    this.loaderService.loading = true;
    this.loaderService.message = 'Registrando usuario';

    const urlApi = `${environment.urlApi}/auth/signup`;

    try {

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(data),
            {
              headers: {
                "content-type": "application/json"
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      console.log(response);

      responseServer = response;      

    } catch(error) {
      console.log(error);
    }

    this.loaderService.loading = false;
    this.loaderService.message = '';

    return responseServer;

  }

  
}
