export interface IServerResponse<T = any> {
  data?: T;
  error: any;
  message: string;
  statusCode: number;
}

export class ServerResponse<T = any> {
  data?: T;
  error: any;
  message: string;
  statusCode: number;

  constructor(data: IServerResponse) {
     this.data = data.data;
     this.error = data.error;
     this.message = data.message;
     this.statusCode = data.statusCode;
  }
}
