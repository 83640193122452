import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataFormModel } from '../models/data-form-model.model';
import { Table } from 'primeng/table';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  clearTable(table: Table) {
    table.clear();
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

  buildForm(obj: DataFormModel): FormGroup {

    const group: { [key: string]: FormControl } = {};

    Object.keys(obj).forEach(key => {
      group[key] = new FormControl(obj[key].value, obj[key].validators);
    });

    return new FormGroup(group);
    
  }
}
