<div class="modal-header">
  <h4 class="modal-title">
    @if (company) {
      Editar compañia {{ company.name }} 
    }

    @if (!company) {
      Nueva compañia
    }
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form
    id="formCompany"
    [formGroup]="formCompany"
    (ngSubmit)="onSubmit()">
    <div class="container-fluid">
      <div class="row">

        @if(imageBase64 || (company && company.file)) {
          <div class="col-12">
            <img [src]="imageBase64 ? imageBase64 : (company && company!.file) ? company.file.urlFile : ''" width="100%">
          </div>
        }

        @for (key of utilService.objectKeys(dataForm); track key) {
          @if (dataForm[key].type != "select" && dataForm[key].type != "image") {
            <div class="col-12 form-group mb-4">
              <label [htmlFor]="key" class="form-label">
                {{ dataForm[key].label }}
              </label>
              <input
                [type]="dataForm[key].type"
                class="form-control"
                formControlName="{{ key }}"
                [ngClass]="{
                  'is-invalid': submitted && f[key].errors
                }">
                @if (submitted && f[key].errors && dataForm[key].required) {
                  <div class="invalid-feedback">
                    @if (f[key].errors!['required']) {
                      <div>{{ dataForm[key].requiredMessage }}</div>
                    }
                  </div>
                }
            </div>
          }

          @if(dataForm[key].type == 'image') {
            <div class="col-12 form-group mb-4">
              <label [htmlFor]="key" class="form-label">
                {{ dataForm[key].label }}
              </label>
              <input 
                class="form-control" 
                type="file" 
                [id]="key"
                (change)="onFileChange($event)">
            </div>
          }
        }

      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-outline-danger w-100">
      Cancelar
    </button>
  </div>
  <div class="col-12">
    <button
      type="submit"
      form="formCompany"
      class="btn btn-primary w-100">
      Registrar
    </button>
  </div>
</div>